import Cookies from 'js-cookie'

const PrivateKeyKey = 'Admin-PrivateKey-xinhuaweb'
const UserId = 'Admin-user-code'
const DeviceTokenKey = 'DeviceToken-xinhuaweb'
const LoginInfoKey = 'xinhuaweb-loginInfo'

export function getPrivateKey() {
    return Cookies.get(PrivateKeyKey)
}

export function setPrivateKeyVal(privateKey) {
    return Cookies.set(PrivateKeyKey, privateKey)
}
export function getUserId() {
    return Cookies.get(UserId)
}

export function setUserId(userId) {
    return Cookies.set(UserId, userId)
}

export function removeUserId() {
    return Cookies.remove(UserId)
}
export function removePrivateKey() {
    return Cookies.remove(PrivateKeyKey)
}

export function getDeviceToken() {
    return Cookies.get(DeviceTokenKey)
}

export function setDeviceToken(DeviceToken) {
    return Cookies.set(DeviceTokenKey, DeviceToken)
}

export function removeDeviceToken() {
    return Cookies.remove(DeviceTokenKey)
}
export function getLoginInfo() {
    let returnInfo = Cookies.get(LoginInfoKey)
    if(returnInfo){
        returnInfo = JSON.parse(returnInfo)
    }
    return returnInfo
}

export function setLoginInfo(LoginInfo) {
    let setInfo = LoginInfo
    if(setInfo){
        setInfo = JSON.stringify(setInfo)
    }
    return Cookies.set(LoginInfoKey, setInfo)
}

export function removeLoginInfo() {
    return Cookies.remove(LoginInfoKey)
}

