<template>
  <div class="footer">
    <div class="footer-container">
      <div class="copyright">
        <a class="logo" title="几人作" to="/home">
          <img src="@/assets/images/logo.png" alt="" />
          <el-link
            :underline="false"
            style="font-size: 18px; font-weight: 600; color: #fff"
            >幾人作</el-link
          >
        </a>
        <div class="center">
          <p>关于几人作</p>
          <p>用户协议</p>
          <p>用户指南</p>
        </div>
        <div class="black">
          <p>联系我们</p>
          <div class="items">在线留言</div>
          <div class="items">电邮</div>
          <div class="items">微信</div>
          <div class="items">Twitter</div>
          <div class="items">Discord</div>
        </div>
      </div>
      <p>Copyright © 2023-2025 www.jirenzuo.com All Rights</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
};
</script>

<style scoped lang="less">
.footer {
  background: linear-gradient(180deg, #21262a 0%, #07080d 100%);

  .footer-container {
    width: 1200px;
    margin: 0 auto;
    .copyright {
      width: 100%;
      padding: 40px 0 20px;
      display: flex;
      .logo {
        display: flex;
        align-items: center;
        img {
          width: 36px;
          margin-right: 10px;
        }
      }
      .center {
        margin-left: 100px;
        display: flex;
        align-items: center;
        > p {
          width: 100px;
        }
      }
      .black {
        height: 50px;
        margin-left:100px;
        display: flex;
        align-items: center;
        border-radius: 30px;
        background: #000000;
        text-align: center;
        padding: 0 20px;
        .items {
          width: 90px;
          color: #828282;
        }
        > p {
          width: 100px;
        }
      }
    }
    > p {
      padding: 30px 0;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
