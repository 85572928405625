import {
  reqLogin,
  getHome,
  reqLogout
} from "@/api";
import {
  getPrivateKey,
  setPrivateKeyVal,
  removePrivateKey,
  setUserId,
  getUserId,
  removeUserId,
  getLoginInfo,
  setLoginInfo,
} from "@/utils/auth.js";
import {
  setHomeList,
  clearHomeList,
  getHomeList
} from "@/utils/home.js";
//登录与注册模块仓库
const state = {
  privateKey: getPrivateKey(),
  accountId: getUserId(),
  userInfo: getLoginInfo() || {
    nickName: "", // 昵称
    noticeNum: "", //公告数量未读数量
    tipNum: "", //平台消息未读数量
    userHead: "", //当前用户头像
    walletAddress: "", // 手机号
    walletBalance: '', // 余额
    walletType: '', // 1 DAIC 3 =ETH
    walltId: '', //钱包ID
  },
  homeList: getHomeList(),

};
const mutations = {
  SETPRIVATEKEY(state, privateKey) {
    state.privateKey = privateKey;
    setPrivateKeyVal(privateKey);
  },
  SETUSERID(state, accountId) {
    state.accountId = accountId;
    setUserId(accountId);
  },
  //存储用户信息
  GETUSERINFO(state, userInfo) {
    state.userInfo = userInfo;
    setLoginInfo(userInfo);
  },
  //存首页列表
  GETHOMELIST(state, list) {
    state.homeList = list;
    setHomeList(list);
  },
  //退出登录情况全部用户信息
  USERLOGOUT(state) {
        //本地存储数据【accountId】
    removeUserId()
    state.userInfo = {};
    //本地存储数据【token】
    removePrivateKey();
  },
};
const actions = {
  //登录业务
  async userLogin({
    commit
  }, data) {
    //当前的这个action，向服务器发起登录请求
    let result = await reqLogin(data);
    //切记:当用户登录成功以后，服务器会返回相应数据信息，数据信息当中包含token
    //登录成功一定是有token，登录没有成功【没有token】
    //用户登录成功，一般只是返回token，很少返回用户名
    //将来需要在发请求（获取用户名字的）携带token给服务器【用户信息】
    if (result.code == 1000) {
      //如果仓库,已经存储token,用户一定是登陆了
      commit("SETPRIVATEKEY", result.data.privateKey);
      commit("SETUSERID", result.data.accountId);
      return "ok";
    } else {
      //登录失败
      return Promise.reject(result.msg);
    }
  },
  //获取用户信息:只要出发这个action，就可以获取用户信息
  async getHomeInfo({
    commit
  }) {
    //为什么用户已经登录：但是获取不到用户信息，因为获取用户信息需要携带token
    //这样服务器才知道你是谁，才会返回相应的用户额信息
    let result = await getHome({
      accountId: state.accountId || "",
      pageNo: 1,
      pageSize: 12,
    });
    if (result.code == 1000) {
      if (result.data.nickName) {
        let {
          nickName,
          noticeNum,
          tipNum,
          userHead,
          walletAddress,
          walletBalance,
          walletType,
          walltId,
        } = result.data;
        commit("GETUSERINFO", {
          nickName,
          noticeNum,
          tipNum,
          userHead,
          walletAddress,
          walletBalance,
          walletType,
          walltId,
        });
      }

      commit("GETHOMELIST", result.data.articleList);

      return result.data.articleList;
    } else {
      return Promise.reject(new Error("faile"));
    }
  },
  //退出登录
  async userLogout({
    commit,
    accountId
  }) {
    //发请求通知服务器销毁token
    let result = await reqLogout({accountId});
    if (result.code == 1000) {
      commit("USERLOGOUT");
    }
  },
};
const getters = {};
export default {
  state,
  mutations,
  actions,
  getters,
};